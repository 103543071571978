import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bb69d382"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "padding-top": "100px",
    "text-align": "center",
    "width": "100%"
  },
  class: "body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    src: "/assets/yf.gif",
    style: {
      "width": "200px",
      "height": "200px"
    }
  })]);
}