

export default {
    //方法一扩展（C#中PadLeft、PadRight）
    PadLeft: function (src, len, charStr) {
        var s = src + '';
        return new Array(len - s.length + 1).join(charStr, '') + s;
    },
    PadRight: function (src, len, charStr) {
        var s = src + '';
        return s + new Array(len - s.length + 1).join(charStr, '');

    }
}